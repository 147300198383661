.loaderContainer {
  background-color: rgb(251, 251, 251);
  margin-top: 30px;
}

.stageContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.stage {
  transition: opacity 2s ease-in;
  position: absolute;
  width: 67%;
  .loadingText {
    width: 85%;
    margin: auto;

    font-size: 14px;
    line-height: 19px;
    color: #0e1738;
    margin: 50px 20px 20px 20px;
    text-align: center;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo {
    width: 100%;
    height: 87px;
    margin: auto;
    margin-top: 20px;
    #logo {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      height: inherit;
    }
  }
  .stageImageContainer {
    height: 300px;
    margin: auto;
  }
  .timingInfo {

    font-size: 14px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    margin: 20px;
    color: #97a0b1;
    display: block;
  }

  &.fadeOut {
    opacity: 0;
    z-index: -1;
  }
}

.loaderImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
