*,
*::before,
*::after {
  font-family: 'Montserrat', 'Rubik', sans-serif !important;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #fbfbfb;
  box-sizing: border-box;
  font: 16px "Open Sans", sans-serif;
  /* -webkit-overflow-scrolling: touch; */
  overflow: overlay;
  
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

button {
  cursor: pointer;
  border-radius: 8px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.gm-svpc {
  bottom: 0 !important;
  top: initial !important;
  display: none;
}

.gm-style {
 padding: 10px 10px;
}

.gmnoprint {
  top: initial !important;
}

.gm-compass {
  top: initial !important;
  bottom: 60px;
}

.material-button {
  width: 252px;
  height: 56px;
  box-shadow: none;
  margin-top: 1rem;
  cursor: pointer;
  font-family: 'Montserrat', 'Rubik', sans-serif !important;
  text-transform: capitalize;
  font-size: 20px;
  align-self: center;
  border-radius: 8px;
}

.material-input {
  width: 100%;
  border-radius: 8px !important;
}

.onboarding-container::-webkit-scrollbar {
  display: none;
}

@keyframes sk-rotate {
  100% { 
    transform: rotate(360deg); 
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}