input,button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 95%;
  padding: 11px 15px 11px 5px;
}