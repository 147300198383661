div#wave {
  position:relative;
  text-align:center;
  width:5rem;
  height:100px;
  display: inline-block;
  margin-right: auto;
  .dot {
    display:inline-block;
    width:6px;
    height:6px;
    border-radius:50%;
    margin-right:3px;
    background:#0E1738;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

.childText {
  font-size: 18px;
  
  line-height: 15px;
  margin-top: 25px;
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}